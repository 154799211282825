import React from "react";
import FontAwesome from "react-fontawesome";
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

function Footer() {
    return (
        <div className="footer">
            <footer className="py-3 bg-dark">
                <div className="container">
                    <div className="row align-items-center justify-content-center my-5">
                        <div className="col-lg-4 col-sm-12">
                            <div className="row justify-content-center ">
                                <img className="img-fluid mb-4" src="./img/bf-logo.png" alt="" />
                            </div>
                            <div className="row justify-content-center ">
                                <div className="text-center mr-3">
                                    <FontAwesome className="mb-2" name="map-marker" size="2x" />
                                    <br />
                                    <FontAwesome name="mobile" size="2x" />
                                    <br />
                                    <FontAwesome name="envelope" />
                                </div>
                                <div className="float-left">
                                    <address>
                                        Intervention sur Brest<br />
                                        et la région Bretagne<br />
                                        Tél : <a href="tel:0782089081" target="_blank" rel="noreferrer">0782089081</a><br />
                                        Mail : <a href="mailto:contact@bananaflip.fr" target="_blank" rel="noreferrer">contact@bananaflip.fr</a>
                                    </address>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="row justify-content-center ">
                                <motion.div whileHover={{ scale: 1.1 }} transition={transition}>
                                    <a href="https://www.facebook.com/BananaFlipBrest" rel="noreferrer" target="_blank">
                                        <FontAwesome className="mr-3" name="facebook" size="3x" />
                                    </a>
                                </motion.div>
                                <motion.div whileHover={{ scale: 1.1 }} transition={transition}>
                                    <a href="https://www.instagram.com/bananaflipbrest/" rel="noreferrer" target="_blank">
                                        <FontAwesome className="mr-3" name="instagram" size="3x" />
                                    </a>
                                </motion.div>
                                <motion.div whileHover={{ scale: 1.1 }} transition={transition}>
                                    <a href="mailto:contact@bananaflip.fr" rel="noreferrer" target="_blank">
                                        <FontAwesome className="mr-3" name="envelope" size="3x" />
                                    </a>
                                </motion.div>
                            </div>
                            <div className="row mt-5 justify-content-center ">
                                <a href="/Mentions-legales" className="text-center">
                                    Mentions légales
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="bf-button">
                                <a href="/" >Retour à l'accueil</a>
                            </div>
                        </div>
                    </div>
                    <p className="text-center">
                        Copyright &copy; Banana Flip { (new Date().getFullYear()) }
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default Footer;