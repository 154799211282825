import React from "react";
import ReCaptchaV2 from 'react-google-recaptcha';
import axios from "axios";
import { motion } from "framer-motion";
import sanityClient from "../client.js";
import BlockContent from '@sanity/block-content-to-react';

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

class Reviews extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            city: '',
            date: '',
            email: '',
            message: '',
            messageStatut: '',
            reviews: []
        }

        this.serializers = {
            types: {
                code: props => (
                    <pre data-language={props.node.language}>
                        <code>{props.node.code}</code>
                    </pre>
                )
            }
        };
    }

    componentDidMount() {

        sanityClient.fetch(`*[_type == 'review']| order(date desc)[0...4]  {
            _id,
            name,
            city,
            date,
            message   
        }`)
            .then(data => {
                this.setState({ reviews: data }, () => {

                });
            })
            .catch(console.error);
    }

    render() {
        return (
            <motion.div className="page" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0, transition: transition }}>
                <div className="container">
                    <div className="row align-items-center my-5">
                        <div className="col-lg-7">
                            <img
                                loading="lazy"
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src="/img/content/bananaflip-avis-flipper-services-brest.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-5">
                            <h1 className="font-weight-light">Avis clients</h1>
                            <p>
                                N’hésitez pas à nous laisser votre avis sur nos services pour partager votre expérience avec Banana Flip.
                            </p>
                            <div className="bf-button">
                                <a href="#write_review">Laisser un avis</a>
                            </div>
                        </div>
                    </div>

                    <div id="more" className="row align-items-center justify-content-center my-5">
                        <div className="col-lg-12">
                            <ul className="bf-reviews">
                                {
                                    this.state.reviews.map((r, i) => {
                                        return <li key={i} className="bf-reviews-item">
                                            <div className="bf-reviews-item-header"><b>{r.name}</b>, le <i>{r.date}</i> à <span>{r.city}</span></div>
                                            <BlockContent blocks={r.message} projectId="552cpgqt" dataset="production" serializers={this.serializers} />
                                        </li>
                                    })
                                }
                            </ul>
                        </div>

                        <div className={`bf-button ${this.state.reviews.length === 0 ? 'd-none' : ''}`} onClick={() => {
                            sanityClient.fetch(`*[_type == 'review'] | order(date desc) {
                                _id,
                                name,
                                city,
                                date,
                                message   
                            }`)
                                .then(data => {
                                    this.setState({ reviews: data });
                                    document.getElementById("button-more").innerHTML = "Voir moins";

                                    /// TODO ::: MOINS

                                })
                                .catch(console.error);
                        }}>
                            <a id="button-more" href="#more">Voir plus d'avis</a>
                        </div>
                    </div>

                    <div id="write_review" className="row align-items-center justify-content-center my-5">
                        <div className="col-lg-7">
                            <h2 className="font-weight-light">Rédiger un avis</h2>
                        </div>
                        <div className="col-lg-7">

                            <form id="review-form" onSubmit={this.handleSubmit.bind(this)} method="POST" autoComplete="on">
                                <div className="row form-group">
                                    <div className="col">
                                        <label htmlFor="name">Votre nom</label>
                                        <input type="text" name="given-name" className="form-control" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="city">Votre ville</label>
                                        <input type="text" name="address-level1" className="form-control" value={this.state.city} onChange={this.onCityChange.bind(this)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Votre mail</label>
                                    <input type="email" name="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Votre avis</label>
                                    <textarea className="form-control" rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                                </div>
                                <ReCaptchaV2
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={this.handleToken.bind(this)}
                                    onExpired={this.handleExpire.bind(this)}
                                />
                                <button type="submit" className="btn btn-primary">Envoyer</button>
                                <p className="alert-success">{this.state.messageStatut}</p>
                            </form>

                        </div>
                    </div>
                </div>
            </motion.div>
        );
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onCityChange(event) {
        this.setState({ city: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault();

        axios({
            method: "POST",
            url: window.location.protocol + "//admin.bananaflip.fr/sendReview.php",
            data: this.state
        }).then((response) => {
            if (response.data.status === 'success') {
                this.setState({
                    name: '',
                    city: '',
                    email: '',
                    date: '',
                    message: '',
                    messageStatut: 'Votre avis a bien été envoyé !'
                });
            } else if (response.data.status === 'fail') {
                this.setState({
                    name: '',
                    city: '',
                    email: '',
                    date: '',
                    message: '',
                    messageStatut: 'Echec de l\'envoi de l\'avis.'
                });
            }
        })
    }

    handleToken(token) {
        this.setForm((currentForm) => {
            return { ...currentForm, token }
        })
    }

    handleExpire() {
        this.setForm((currentForm) => {
            return { ...currentForm, token: null }
        })
    }

}

export default Reviews;