import { BrowserRouter } from "react-router-dom";

import AnimatedRoutes from "./AnimatedRoutes";
import { Navigation, Footer } from "./components";
import "./index.css";

function App() {
    return (
        <BrowserRouter>
            <Navigation />
            <AnimatedRoutes />
            <Footer />
        </BrowserRouter>
    );
}

export default App;
