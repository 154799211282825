import React from "react";
import sanityClient from "../client.js";
import BlockContent from '@sanity/block-content-to-react';
import ListPinballs from "./ListPinballs";
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

class Vente extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            venteIntro: []
        };
    }

    componentDidMount() { 
        // Get site config
        sanityClient.fetch(`*[_type == 'siteSettings'][0]`)
            .then(data => {
                this.setState({ 
                    venteIntro: data.venteIntro
                });
            })
            .catch(console.error);
    }
    render() {
        return (
            <motion.div className="page" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0, transition: transition }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center my-5">
                        <div className="col-lg-8 framed">
                            <BlockContent blocks={this.state.venteIntro} projectId="552cpgqt" dataset="production" serializers={this.serializers} />
                        </div>
                    </div>
                    <ListPinballs type="vente" />
                </div>
            </motion.div>
        );
    }
}

export default Vente;