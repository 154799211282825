import React from "react";
import { motion } from "framer-motion";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

class Services extends React.Component {

    constructor(props) {
        super(props);

        this.props = props;
        this.autoSlideTimer = null;
        this.autoSlide = 0;
        this.slide = 0;
        this.imgCount = { "location": 5, "depannage": 3, "entretien": 6, "customisation": 7, "restauration": 5 }
    }

    render() {
        return (
            <motion.div className="page" initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: transition }}>
                <div className="container">
                    <div id="location" className="row align-items-center my-5">
                        <div className="col-lg-7">
                            <motion.img
                                whileHover={{ scale: 1.05 }}
                                transition={transition}
                                loading="lazy"
                                className="img-fluid rounded mb-4 mb-lg-0 zoomable"
                                src="/img/content/location_00.jpg"
                                alt=""
                                onClick={(e) => this.onToggleDiaporama(e, true)}
                                onMouseEnter={(e) => this.onToggleSlideThumbnail(e, true)}
                                onMouseLeave={(e) => this.onToggleSlideThumbnail(e, false)}
                            />
                        </div>
                        <div className="col-lg-5">
                            <h2 className="mb-3">Location</h2>
                            <p>
                                Pour vous amuser lors des journées de pluie, animer votre évènement ou un week-end entre amis, ou encore profiter d’un vrai jeu de café dans votre salon pour y jouer jusqu’à l’épuisement, Banana Flip vous propose son service de location.
                                Week-end, semaine ou mois, à vous de choisir parmi nos machines disponibles.
                                L’installation est effectuée par nos soins à votre domicile.
                            </p>
                            <p>
                                La location est également disponible pour les entreprises.
                                Pour équiper votre salle de pause, attirer les regards sur votre stand lors d’un salon, ou proposer un divertissement original lors de vos événements professionnels, Banana Flip vous propose ses machines en location pour une journée, un week-end ou même à l’année.
                            </p>
                        </div>
                    </div>
                    <div id="entretien" className="row align-items-center my-5">
                        <div className="col-lg-6">
                            <h2 className="mb-3">Entretien / Révision</h2>
                            <p>Pour profiter pleinement de votre flipper et atteindre des scores record, votre machine mérite un entretien régulier.</p>

                            <p>En effet, au fil de vos parties, de nombreuses pièces mécaniques sont sollicitées (butées, contacts, fourreaux…), de plus, la bille vient percuter des éléments fixe du jeu à
                                forte vitesse, ce qui peut entrainer de la casse.</p>

                            <p>Le plateau s’use, il est important de le nettoyer et de le protéger pour qu’il dure. (Nettoyage/protection du plateau conseillés tous les 2 mois ou 200 parties)</p>

                            <p>C’est pourquoi Banana Flip vous propose un forfait "Entretien / Révision", véritable "check up" de votre machine, la révision comprend un nettoyage et polissage du plateau,
                                la vérification du bon fonctionnement des mécanismes et le remplacement des pièces d’usure si besoin. </p>
                        </div>
                        <div className="col-lg-6">
                            <motion.img
                                whileHover={{ scale: 1.05 }}
                                transition={transition}
                                loading="lazy"
                                className="img-fluid rounded mb-4 mb-lg-0 zoomable"
                                src="/img/content/entretien_00.jpg"
                                alt=""
                                onClick={(e) => this.onToggleDiaporama(e, true)}
                                onMouseEnter={(e) => this.onToggleSlideThumbnail(e, true)}
                                onMouseLeave={(e) => this.onToggleSlideThumbnail(e, false)}
                            />
                        </div>
                    </div>
                    <div id="depannage" className="row align-items-center my-5">
                        <div className="col-lg-5">
                            <motion.img
                                whileHover={{ scale: 1.05 }}
                                transition={transition}
                                loading="lazy"
                                className="img-fluid rounded mb-4 mb-lg-0 zoomable"
                                src="/img/content/depannage_00.jpg"
                                alt=""
                                onClick={(e) => this.onToggleDiaporama(e, true)}
                                onMouseEnter={(e) => this.onToggleSlideThumbnail(e, true)}
                                onMouseLeave={(e) => this.onToggleSlideThumbnail(e, false)}
                            />
                        </div>
                        <div className="col-lg-7">
                            <h2 className="mb-3">Dépannage / Réparation</h2>
                            <p>
                                Un flipper, même entretenu, n’est pas à l’abri d’une panne qui peut gêner, voir empêcher son bon fonctionnement.
                                Les composants électroniques ont une durée de vie limitée et certaines pièces du plateau sont soumises à de fortes contraintes mécaniques.
                                Lorsqu’une panne survient, il est important de s’en occuper rapidement pour ne pas qu’elle s’aggrave ou en provoque d’autres.
                                C’est pourquoi Banana Flip vous propose son service de dépannage directement à votre domicile ou dans notre atelier.
                            </p>
                        </div>
                    </div>
                    <div id="restauration" className="row align-items-center my-5">
                        <div className="col-lg-5">
                            <h2 className="mb-3">Restauration</h2>
                            <p>
                                Si vous souhaitez donner une deuxième jeunesse à votre flipper, effacer les traces d’années d’exploitations dans des bars enfumés,
                                de la caisse au plateau, Banana Flip met ses compétences à votre service pour rendre à votre machine sa splendeur d’origine.<br></br>
                            </p>
                            <ul>
                                <li>Devis sur demande</li>
                                <li>Peinture de caisse/pose de décals</li>
                                <li>Retouche peinture / application vernis plateau</li>
                                <li>Fabrication de pièces fidèle à l’origine</li>
                            </ul>
                        </div>
                        <div className="col-lg-7">
                            <motion.img
                                whileHover={{ scale: 1.05 }}
                                transition={transition}
                                loading="lazy"
                                className="img-fluid rounded mb-4 mb-lg-0 zoomable"
                                src="/img/content/restauration_00.jpg"
                                alt=""
                                onClick={(e) => this.onToggleDiaporama(e, true)}
                                onMouseEnter={(e) => this.onToggleSlideThumbnail(e, true)}
                                onMouseLeave={(e) => this.onToggleSlideThumbnail(e, false)}
                            />
                        </div>
                    </div>
                    <div id="customisation" className="row align-items-center my-5">
                        <div className="col-lg-6">
                            <motion.img
                                whileHover={{ scale: 1.05 }}
                                transition={transition}
                                loading="lazy"
                                className="img-fluid rounded mb-4 mb-lg-0 zoomable"
                                src="/img/content/customisation_00.jpg"
                                alt=""
                                onClick={(e) => this.onToggleDiaporama(e, true)}
                                onMouseEnter={(e) => this.onToggleSlideThumbnail(e, true)}
                                onMouseLeave={(e) => this.onToggleSlideThumbnail(e, false)}
                            />
                        </div>
                        <div className="col-lg-6">
                            <h2 className="mb-3">Customisation</h2>
                            <p>Pour donner un coup de peps à votre flipper et lui donner l’image que vous souhaitez, Banana Flip vous propose son service de customisation.
                                De la pose de stickers à la création de décorations originales, faites-vous plaisir en améliorant votre expérience de jeu.</p>
                            <p>Grâce à des techniques innovantes telles que l’impression 3D ou l’utilisation de résines, nous vous proposons des gadgets et décors uniques pour personnaliser votre machine.
                                Les quelques modèles déjà réalisés ne sont qu’un aperçu des possibilités, n’hésitez pas à nous contacter avec vos idées. </p>
                            <ul>
                                <li>Passage en Led</li>
                                <li>Fabrication porte clés custom</li>
                                <li>Pose de décors intérieur de caisse, stickers ou plaque métallique</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="fullpage" >
                    <div id="fullpage-img" onClick={(e) => this.onToggleDiaporama(e, false)}></div>

                    <button className="prev" onClick={(e) => { this.onChangeSlide(e, -1) }}>&#10094;</button>
                    <button className="next" onClick={(e) => { this.onChangeSlide(e, 1) }}>&#10095;</button>
                    <button className="exit" onClick={(e) => this.onToggleDiaporama(e, false)}>&#10006;</button>
                </div>
            </motion.div>
        );
    }

    onToggleDiaporama(e, state) {
        const fullPage = document.querySelector('#fullpage');
        const imgset = e.target.parentNode.parentNode.id;
        const img = document.querySelector('#fullpage-img');
        if (state) {
            this.slide = 0;
            img.style.backgroundImage = 'url(/img/content/' + imgset + '_00.jpg)';
            img.style.width = '85vw';
            img.style.height = '90vh';
            fullPage.style.width = '100vw';
            fullPage.style.height = '100vh';
            fullPage.style.opacity = '1';
            fullPage.dataset.imgset = imgset;
        } else {
            fullPage.style.width = '0';
            fullPage.style.height = '0';
            fullPage.style.opacity = '0';
        }
    }
    onToggleSlideThumbnail(e, state) {
        const curID = e.target.parentNode.parentNode.id;
        if (state) {
            this.autoSlideTimer = setInterval(() => {
                if (this.autoSlide < this.imgCount[curID] - 1)
                    this.autoSlide++;
                else this.autoSlide = 0;
                e.target.src = "/img/content/" + curID + "_0" + this.autoSlide + ".jpg";
            }, 900);
        } else {
            clearInterval(this.autoSlideTimer);
            e.target.src = "/img/content/" + curID + "_00.jpg";
            this.autoSlide = 0;
            this.autoSlideTimer = null;
        }
    }
    onChangeSlide(e, n) {
        const img = document.querySelector('#fullpage-img');
        const imgset = e.target.parentNode.dataset.imgset;
        var maxSlides = this.imgCount[imgset] - 1;
        if (this.slide + n > maxSlides || this.slide + n < 0) {
            this.slide = 0;
        } else {
            this.slide += n;
        }
        img.style.backgroundImage = 'url(/img/content/' + imgset + '_0' + this.slide + '.jpg)';
    }
}

export default Services;